import * as THREE from "three";
import { Pane } from "tweakpane";
import * as TweakpaneThumbnailListPlugin from 'tweakpane-plugin-thumbnail-list';
import { saveAs } from 'file-saver';
import * as CCapture from 'ccapture.js-npmfixed';

import vertex from "./shader--vertex.glsl";
import fragment from "./shader--fragment.glsl";

const scriptLog = "🖼 CANVAS |";

export default class Canvas {
  constructor(el) {
    this.DOM = { el };
    console.log(`${scriptLog} constructor | this.DOM.el`, this.DOM.el);

    this.W = 1;
    this.H = 1;

    this.paused = false;
    this.animate = false;

    this.exportingDiv = document.querySelector('#exporting');
    this.exportingProgress = this.exportingDiv.querySelector('#progress');

    this.capturing = {
      enabled: false,
      frameRate: 60,
      seconds: 2,
      frameMax: 120,
      currentFrame: 0
    };

    this.image = document.querySelector("#image");

    this.time = 0;
    this.timeSin = 0;
    this.clock = new THREE.Clock();

    this.capturer = new CCapture( {
      format: 'webm',
      framerate: this.capturing.frameRate,
      timeLimit: this.capturing.seconds,
      quality: 100,
      motionBlurFrames: 1
    });

    this.settings();
  }

  settings() {
    this.settings = {
      canvas: {
        w: 3840,
        h: 2160,
        zoom: 0.2
      },
      image: {
        src: false,
        scale: 1,
        perspective: 100,
        position: {
          x: 0,
          y: 0,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0
        }
      },
      pattern: {
				repeat: {
					x: 18,
					y: 1
				},
				rotate: {
					x: -1.536,
				},
				gradient: {
					start: -1.07,
					end: -0.10
				}
			},
      mouse: {
        x: 0.0,
        y: 0.0,
      },
    };
    this.init();
  }

  init() {
    console.log(`${scriptLog} init | this`, this);

    this.mainScene = new THREE.Scene();

    this.initCamera();
    this.initLights();

    this.addObjects();

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.DOM.el,
      // alpha: true,
      outputEncoding: THREE.sRGBEncoding,
      // antialias: true,
    });
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

    this.renderer.domElement.getContext("webgl") ||
      this.renderer.domElement.getContext("experimental-webgl");

    this.devicePixelRatio = window.devicePixelRatio > 1 ? 2 : 1;
    this.W = Math.floor(this.settings.canvas.w * window.devicePixelRatio);
    this.H = Math.floor(this.settings.canvas.h * window.devicePixelRatio);
    
    this.DOM.el.style.width = `${this.settings.canvas.w}px`;
    this.DOM.el.style.height = `${this.settings.canvas.h}px`;
    this.DOM.el.style.setProperty('--canvas-scale', this.settings.canvas.zoom);

    this.imageTexture = false;

    this.renderer.setSize(this.settings.canvas.w, this.settings.canvas.h, false);
    this.renderer.setPixelRatio(this.devicePixelRatio);

    this.renderer.render(this.mainScene, this.camera);

    this.update();
    this.bindEvent();
  }

  bindEvent() {
    window.addEventListener("resize", () => {
      this.onResize();
    });

    this.loaded();
  }

  loaded() {
    this.DOM.el.classList.add("loaded");
    document.documentElement.style.setProperty("--vh", window.innerHeight);
  }

  onResize() {
    this.devicePixelRatio = window.devicePixelRatio > 1 ? 2 : 1;
    this.W = Math.floor(this.settings.canvas.w * window.devicePixelRatio);
    this.H = Math.floor(this.settings.canvas.h * window.devicePixelRatio);

    this.DOM.el.style.width = `${this.settings.canvas.w}px`;
    this.DOM.el.style.height = `${this.settings.canvas.h}px`;
    this.DOM.el.style.setProperty('--canvas-scale', this.settings.canvas.zoom);

    this.fov = (180 * (2 * Math.atan(this.settings.canvas.h / this.settings.image.perspective))) / Math.PI;
    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      this.settings.canvas.w / this.settings.canvas.h,
      1,
      10000
    );
    this.plane.scale.set(this.imageTexture.image.width * 2 * this.settings.image.scale, this.imageTexture.image.height * 2 * this.settings.image.scale, 1);
    this.camera.position.set(0, 0, this.settings.image.perspective);
    this.renderer.setSize(this.settings.canvas.w, this.settings.canvas.h, false);
    this.renderer.setPixelRatio(this.devicePixelRatio);
  }

  update() {
    if (this.paused) return;

    if (this.capturing.enabled) {
      document.body.classList.add('data-exporting');

      if (this.capturing.currentFrame < this.capturing.frameMax) {
        this.capturing.currentFrame += 1;

        this.exportingProgress.textContent = `exporting frame ${this.capturing.currentFrame} / ${this.capturing.frameMax}`;
        console.log(this.capturing.currentFrame, this.capturing.frameMax);
      } else {
        document.body.classList.remove('data-exporting');
        this.capturing.enabled = false;
        this.capturing.currentFrame = 0;
      }
    }

    if (this.animate) {
      this.time += 0.05;
      this.timeSin = Math.sin(this.time);
      this.material.uniforms.u_time.value = this.time;
    }

    if (App.Layout.mouseCoord) {
      this.settings.mouse.x = App.Layout.mouseCoord.x;
      this.settings.mouse.y = App.Layout.mouseCoord.y;
    }
    this.material.uniforms.mouse.value = this.settings.mouse;

    if (this.imageTexture) {
      this.material.uniforms.u_res.value = new THREE.Vector2(this.imageTexture.image.width, this.imageTexture.image.height);
    }

		this.material.uniforms.u_pattern_repeat_x.value = this.settings.pattern.repeat.x;
		this.material.uniforms.u_pattern_repeat_y.value = this.settings.pattern.repeat.y;
		this.material.uniforms.u_pattern_rotate_x.value = this.settings.pattern.rotate.x;
		this.material.uniforms.u_pattern_gradient_start.value = this.settings.pattern.gradient.start;
		this.material.uniforms.u_pattern_gradient_end.value = this.settings.pattern.gradient.end;

    this.renderer.gammaOutput = true;
    this.renderer.render(this.mainScene, this.camera);

    this.capturer.capture( this.DOM.el );

    requestAnimationFrame(this.update.bind(this));
  }

  initCamera() {
    // IF WE NEED PERSPECTIVE CAMERA
    this.fov = (180 * (2 * Math.atan(this.settings.canvas.h / this.settings.image.perspective))) / Math.PI;
    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      this.settings.canvas.w / this.settings.canvas.h,
      1,
      10000
    );
    this.camera.position.set(0, 0, this.settings.image.perspective);

    // IF WE NEED ORTHO CAMERA
    // const frustumSize = 10;
    // const aspect = this.W / this.H;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2,
    // frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000);
  }

  initLights() {
    const ambientlight = new THREE.AmbientLight(0xffffff, 2);
    this.mainScene.add(ambientlight);
  }

  changeImage(base64image) {
    let image = new Image();
    image.src = base64image;

    console.log(image, this.imageTexture)

    const texture1 = new THREE.Texture();
    texture1.image = image;
    image.onload = () => {
      texture1.needsUpdate = true;
      this.imageTexture = texture1;

      this.material.uniforms.u_image.value = this.imageTexture;
      this.onResize();
    }
  }

  changeTexture(url) {
    const loader = new THREE.TextureLoader();
    const texture1 = loader.load(url, () => {
      console.log(texture1)
      this.imageTexture = texture1;
      this.plane.scale.set(this.imageTexture.image.width * 2 * this.settings.image.scale, this.imageTexture.image.height * 2 * this.settings.image.scale, 1);
      this.material.uniforms.u_image.value = this.imageTexture;
      this.onResize();
    });
  }

  addObjects() {
    // const texture = this.image;
    const loader = new THREE.TextureLoader();
    const texture1 = loader.load("https://images.unsplash.com/photo-1579547945413-497e1b99dac0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3840&h=2160&q=10", () => {
      console.log(texture1)
      this.imageTexture = texture1;
      this.plane.scale.set(this.imageTexture.image.width * 2 * this.settings.image.scale, this.imageTexture.image.height * 2 * this.settings.image.scale, 1);
    });


    this.material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: {
        u_image: { type: "t", value: texture1 },
        u_time: { type: "f", value: 0 },
        mouse: { type: "v2", value: App.Layout.mouseCoord },
        u_res: {
          value: new THREE.Vector2(1, 1),
        },
        f_image_scale: { type: "f", value: this.settings.image.scale },
        f_image_rotation: { type: "f", value: this.settings.image.rotation },
        u_pattern_repeat_x : { type: 'f', value: this.settings.pattern.repeat.x },
        u_pattern_repeat_y : { type: 'f', value: this.settings.pattern.repeat.y },
        u_pattern_rotate_x : { type: 'f', value: this.settings.pattern.rotate.x },
        u_pattern_rotate_y : { type: 'f', value: this.settings.pattern.rotate.y },
        u_pattern_gradient_start : { type: 'f', value: this.settings.pattern.gradient.start },
        u_pattern_gradient_end : { type: 'f', value: this.settings.pattern.gradient.end }
      },
      defines: {
				PI: Math.PI,
				PR: window.devicePixelRatio.toFixed(1),
			},
      vertexShader: vertex,
      fragmentShader: fragment,
    });
    this.basicMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

    this.geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);

    this.plane = new THREE.Mesh(this.geometry, this.material);
    
    this.mainScene.add(this.plane);

    // const geometryCube = new THREE.BoxGeometry(this.W - 40, this.H - 40, 1);
    // const materialCube = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    // const cube = new THREE.Mesh(geometryCube, materialCube);
    // this.mainScene.add(cube);

    this.addMonitorsAndSettings();
  }

  resizeCanvas(size) {
    console.log(size);
    this.settings.canvas.w = size.w;
    this.settings.canvas.h = size.h;
    this.onResize();
  }

  addMonitorsAndSettings() {
    this.pane = new Pane({
      title: "🛠 SETTINGS",
      expanded: true,
    });
    this.pane.registerPlugin(TweakpaneThumbnailListPlugin);

    this.pane.addInput(this.settings.canvas, "zoom", {
      interval: 1,
      min: 0.1,
      max: 2,
      label: "Editor Zoom",
    });
    const animateToggle = this.pane.addInput(this, 'animate', {
      label: 'Animated'
    });

    const exportBTN = this.pane.addButton({
      title: 'Export Image'
    });

    const exportVideoBTN = this.pane.addButton({
      title: 'Export video',
      disabled: !this.animate
    });

    animateToggle.on('change', () => {
      exportVideoBTN.disabled = !this.animate;
    });

    this.tabs = this.pane.addTab({
      pages: [
        { title: "CANVAS" },
        { title: "IMAGE" },
        { title: "PATTERN" },
        { title: "MONITORS" }
      ],
    });

    exportBTN.on('click', () => {
      this.renderer.render(this.mainScene, this.camera);
      // const dataURL = this.renderer.domElement.toDataURL('image/png');
      // window.open( dataURL );

      this.renderer.domElement.toBlob(function(blob) {
        saveAs(blob, "pretty image.png");
      });
    });

    exportVideoBTN.on('click', () => {
      this.capturing.enabled = true;
      this.capturer.start();
    });

    const pageCanvas = 0;
    const pageImage = 1;
    const pagePatternDisplace = 2;
    const pageMonitors = 3;

    this.pane.on('change', (ev) => {
      this.fov = (180 * (2 * Math.atan(this.settings.canvas.h / this.settings.image.perspective))) / Math.PI;

      this.plane.rotation.x = this.settings.image.rotation.x;
      this.plane.rotation.y = this.settings.image.rotation.y;
      this.plane.rotation.z = this.settings.image.rotation.z;

      this.plane.position.x = this.settings.image.position.x;
      this.plane.position.y = this.settings.image.position.y;
      this.plane.position.z = this.settings.image.position.z;

      this.camera.position.set(0, 0, this.settings.image.perspective);
      this.camera.updateProjectionMatrix();

      this.onResize();
    });

    this.tabs.pages[pageCanvas].addInput(this.settings.canvas, "w", {
      interval: 1,
      min: 300,
      max: 4000,
      label: "Width",
    });
    this.tabs.pages[pageCanvas].addInput(this.settings.canvas, "h", {
      interval: 1,
      min: 300,
      max: 4000,
      label: "Height",
    });

    const folder_presets = this.tabs.pages[pageCanvas].addFolder({
      title: 'Presets'
    });

    const blade_presetSize = folder_presets.addBlade({
      view: 'list',
      label: 'Canvas size',
      options: [
        {text: 'A4 - Landscape', value: 'A4L'},
        {text: 'A4 - Portrait', value: 'A4P'},
        {text: 'A3 - Landscape', value: 'A3L'},
        {text: 'A3 - Portrait', value: 'A3P'},
        {text: '4K', value: '4K'},
        {text: '1080p', value: '1080p'},
        {text: 'Instagram Stories', value: 'IG'},
        {text: 'SVGA - 800x600', value: 'SVGA'},
        {text: '1:1 - 1024x1024', value: '1024'},
      ],
      value: '4K'
    });
    blade_presetSize.on('change', (ev) => {
      switch(ev.value) {
        case 'A4L':
          this.resizeCanvas({w:3508,h:2480});
        break;

        case 'A4P':
          this.resizeCanvas({h:3508,w:2480});
        break;

        case 'A3L':
          this.resizeCanvas({w:4134,h:2932});
        break;

        case 'A3P':
          this.resizeCanvas({h:4134,w:2932});
        break;

        case '4K':
          this.resizeCanvas({w:3840,h:2160});
        break;

        case '1080p':
          this.resizeCanvas({w:1920,h:1080});
        break;
        case 'IG':
          this.resizeCanvas({w:1080,h:1920});
        break;

        case 'SVGA':
          this.resizeCanvas({w:800,h:600});
        break;
        case '1024':
          this.resizeCanvas({w:1024,h:1024});
        break;
      }
    });

    const blade_presetImages = folder_presets.addInput(this.settings.image, 'src', {
      view: 'thumbnail-list',
      label: 'Preset Images',
      options: [
        {text: 'Abstract 01', value: `https://images.unsplash.com/photo-1579547945413-497e1b99dac0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`, src: `https://images.unsplash.com/photo-1579547945413-497e1b99dac0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`},
        {text: 'Abstract 02', value: `https://images.unsplash.com/photo-1574169207372-88518bd26f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`, src: `https://images.unsplash.com/photo-1574169207372-88518bd26f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`},
        {text: 'Abstract 03', value: `https://images.unsplash.com/photo-1604076913837-52ab5629fba9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`, src: `https://images.unsplash.com/photo-1604076913837-52ab5629fba9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`},
        {text: 'Abstract 04', value: `https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`, src: `https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5000&h=5000&q=10`},
      ],
      value: 'abs_01'
    });
    blade_presetImages.on('change', (ev) => {
      this.changeTexture(ev.value)
    });


    this.tabs.pages[pageImage].addInput(this.settings.image, "scale", {
      interval: 1,
      min: -2,
      max: 4,
      label: "Scale",
    });
    this.tabs.pages[pageImage].addInput(this.settings.image, "perspective", {
      interval: 1,
      min: 100,
      max: 9000,
      label: "Perspective",
    });

    const folder_rotation = this.tabs.pages[pageImage].addFolder({
      title: 'Rotation',
      expanded: false
    });

    folder_rotation.addInput(this.settings.image.rotation, "x", {
      interval: 0.01,
      min: Math.PI * -1,
      max: Math.PI * 1,
      label: "Rotation X",
    });
    folder_rotation.addInput(this.settings.image.rotation, "y", {
      interval: 0.01,
      min: Math.PI * -1,
      max: Math.PI * 1,
      label: "Rotation Y",
    });
    folder_rotation.addInput(this.settings.image.rotation, "z", {
      interval: 0.01,
      min: Math.PI * -1,
      max: Math.PI * 1,
      label: "Rotation Z",
    });

    const folder_position = this.tabs.pages[pageImage].addFolder({
      title: 'Position',
      expanded: false
    });


    folder_position.addInput(this.settings.image.position, "x", {
      interval: 0.01,
      min: (this.settings.canvas.w * 2) * -1,
      max: (this.settings.canvas.w * 2) * 1,
      label: "Position X",
    });
    folder_position.addInput(this.settings.image.position, "y", {
      interval: 0.01,
      min: (this.settings.canvas.w * 2) * -1,
      max: (this.settings.canvas.w * 2) * 1,
      label: "Position Y",
    });
    folder_position.addInput(this.settings.image.position, "z", {
      interval: 1,
      min: -9000,
      max: 10,
      label: "Position Z",
    });




    this.tabs.pages[pagePatternDisplace].addInput( this.settings.pattern.repeat , "x", {
			min: 1,
			max: 30,
			step: 1,
			label: "Repeat X"
		})
		this.tabs.pages[pagePatternDisplace].addInput( this.settings.pattern.repeat , "y", {
			min: 1,
			max: 30,
			step: 1,
			label: "Repeat Y"
		})

    const folder_gradient = this.tabs.pages[pagePatternDisplace].addFolder({
      title: 'Gradient',
      expanded: false
    });

		folder_gradient.addInput( this.settings.pattern.rotate , "x", {
			min: -1 * Math.PI/2,
			max: Math.PI/2,
			label: "Rotation"
		})
		folder_gradient.addInput( this.settings.pattern.gradient , "start", {
			min: -10,
			max: -0.1,
			label: "Start"
		})
		folder_gradient.addInput( this.settings.pattern.gradient , "end", {
			min: -10,
			max: -0.1,
			label: "End"
		})



    this.tabs.pages[pageMonitors].addMonitor(this.settings.mouse, "x", {
      interval: 1,
      label: "🐭 X",
    });
    this.tabs.pages[pageMonitors].addMonitor(this.settings.mouse, "y", {
      interval: 1,
      label: "🐭 X",
    });
    this.tabs.pages[pageMonitors].addMonitor(this, "W", {
      interval: 1,
      label: "RES W",
    });
    this.tabs.pages[pageMonitors].addMonitor(this, "H", {
      interval: 1,
      label: "RES H",
    });
    this.tabs.pages[pageMonitors].addMonitor(this, "timeSin", {
      view: "graph",
      min: -1,
      max: +1,
      interval: 1,
      label: "TIME SIN",
    });
  }
}
