uniform sampler2D u_image;
uniform sampler2D u_hovermap;
uniform sampler2D displaceImage;

uniform float u_alpha;
uniform float u_time;
uniform float u_progressHover;
uniform float u_progressClick;

uniform vec2 u_res;
uniform vec2 u_mouse;
uniform vec2 u_ratio;
uniform vec2 u_hoverratio;
uniform float appScroll;

uniform float u_pattern_repeat_x;
uniform float u_pattern_repeat_y;
uniform float u_pattern_rotate_x;
uniform float u_pattern_rotate_y;
uniform float u_pattern_gradient_start;
uniform float u_pattern_gradient_end;

varying vec2 v_uv;

float circle(in vec2 _st, in float _radius, in float blurriness){
    vec2 dist = _st - vec2(0.5);
    return 1. - smoothstep(_radius-(_radius*blurriness), _radius+(_radius*blurriness), dot(dist,dist)*4.0);
}

float pcurve( float x, float a, float b ){
    float k = pow(a+b,a+b) / (pow(a,a)*pow(b,b));
    return k * pow( x, a ) * pow( 1.0-x, b );
}

vec2 rotate(vec2 v, float a) {
  float s = sin(a);
  float c = cos(a);
  mat2 m = mat2(c, -s, s, c);
  return m * v;
}

void main() {
  vec2 resolution = u_res * PR;
  float time = u_time * 0.05;
  float progress = u_progressClick;
  float progressHover = u_progressHover;

  vec2 uv = v_uv;

  vec2 mouse = vec2((u_mouse.x / u_res.x) * 2. - 1.,-(u_mouse.y / u_res.y) * 2. + 1.) * -.5;
  mouse.y *= resolution.y / resolution.x;

  vec2 uv_mouse = (v_uv * 0.5) + 0.25 + mouse * 0.05;

  vec4 displace = texture2D(displaceImage, uv_mouse );


  vec2 st = v_uv;
  st.x *= u_pattern_repeat_x;
  st.x = fract(st.x);
  st.y *= u_pattern_repeat_y;
  st.y = fract(st.y);

  float y = pcurve( st.x , u_pattern_gradient_start, u_pattern_gradient_end) ;
  // uv = rotate(st, sin( u_time ) + 0.5);
  uv = rotate(st, u_pattern_rotate_x + (uv_mouse.x * 2.));

  vec4 color = vec4(st.x, y * uv.y , y * uv.y, y * uv.y);

  displace = color;
  displace.x += sin(u_time) * 2.5 / (uv_mouse.x * 10.);

  // vec4 originalImage = texture2D(u_map, v_uv * displace.xy * uv_mouse );
  // vec4 displace = texture2D(displaceImage, v_uv + sin(u_time) * 0.05 );
  vec4 image = texture2D(u_image, v_uv * displace.xy );
  // vec4 finalImage = mix(originalImage, image, progressHover );

  gl_FragColor = vec4(image.rgb, 1.0);
}
