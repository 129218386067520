import Layout from "./00-utils/layout";
import Canvas from "./01-atomi/canvas";
import DragDrop from "./01-atomi/dragdrop";

const scriptLog = "🔶 APP |";

const App = window.App || {
  init() {
    window.App = App;
    console.log(`${scriptLog} init`);

    App.Layout = new Layout();
    App.DEBUG = true;

    this.initUniversal();
    if (App.Layout.isMobile !== true) {
      this.initDesktop();
    } else {
      this.initMobile();
    }
  },
  initDesktop() {
    console.log(`${scriptLog} 🖥 Desktop | init`);
  },
  initMobile() {
    console.log(`${scriptLog} 📱 Mobile | init`);
  },
  initUniversal() {
    App.Atomi = [];
    App.Atomi.Canvas = [];
    [...document.querySelectorAll("#canvas")].forEach((item) =>
      App.Atomi.Canvas.push(new Canvas(item))
    );

    App.Atomi.DragDrop = [];
    [...document.querySelectorAll("#drop")].forEach((item) =>
      App.Atomi.DragDrop.push(new DragDrop(item))
    );
  },
};

document.addEventListener("DOMContentLoaded", () => {
  document.documentElement.classList.remove("no-js");
  App.init();
});
