import isMobile from "ismobilejs";

const scriptLog = "📐 LAYOUT |";

export default class Layout {
  constructor() {
    this.DEBUG = false;

    this.isMobile = isMobile().any;
    console.log(`${scriptLog} 📱 isMobile | ${this.isMobile}`);

    document.body.setAttribute("is-mobile", this.isMobile);
    if (!this.isMobile) {
      document.body.classList.add("hasHover");
    }

    this.mouseCoord = [];
    this.mouseCoord.x = 0;
    this.mouseCoord.y = 0;

    this.mouse = [];
    this.mouse.x = 0;
    this.mouse.y = 0;

    this.initMouseListener();
  }

  getPos(event) {
    return {
      x: event.x,
      y: event.y,
    };
  }

  getPosVector(event) {
    return {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    };
  }

  initMouseListener() {
    window.addEventListener("mousemove", (e) => {
      this.mouse = this.getPos(e);
      this.mouseCoord = this.getPosVector(e);

      if (this.DEBUG) {
        console.log(`${scriptLog} 🐭 this.mouse`, this.mouse);
        console.log(`${scriptLog} 🐭 this.mouseCoord`, this.mouseCoord);
      }
    });

    window.addEventListener("touchmove", (e) => {
      // console.log(e.changedTouches[0]);
      this.mouse.x = e.changedTouches[0].clientX;
      this.mouse.y = e.changedTouches[0].clientY;
      // this.mouseCoord = this.getPosVector(e.changedTouches[0]);
    });
  }
}
