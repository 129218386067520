const scriptLog = "😵‍💫 DRAG DROP |";

export default class DragDrop {
  constructor(el) {
    this.DOM = { el };
    console.log(`${scriptLog} constructor | this.DOM.el`, this.DOM.el);

    this.initEvents();
  }

  

  initEvents() {
    let uploaded_image = false;

    const preventDefaults = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
  
    const highlight = () => {this.DOM.el.classList.add('highlight')}
    const unhighlight = () => {this.DOM.el.classList.remove('highlight')}
  
    const handleDrop = (e) => {
      const dt = e.dataTransfer;
      const files = dt.files;
    
      handleFiles(files);
    }
  
    const handleFiles = (files) => {
      ([...files]).forEach((file) => {
        console.log(`${scriptLog} FILE:`, file);
        readImage(file);
      })
    }

    const readImage = (file) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        uploaded_image = event.target.result;
        document.querySelector("#image_drop_area").style.backgroundImage = `url(${uploaded_image})`;
        App.Atomi.Canvas[0].changeImage(uploaded_image);
      });
      reader.readAsDataURL(file);

      
   }

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.DOM.el.addEventListener(eventName, preventDefaults, false);
    });

    ;['dragenter', 'dragover'].forEach(eventName => {
      this.DOM.el.addEventListener(eventName, highlight, false);
    });
    
    ;['dragleave', 'drop'].forEach(eventName => {
      this.DOM.el.addEventListener(eventName, unhighlight, false);
    });

    this.DOM.el.addEventListener('drop', handleDrop, false);
  }
}
